import React from "react";
import { graphql, Link } from "gatsby";
import PropTypes from "prop-types";

// Layout
import SecondaryLanding from "../layouts/secondary-landing";
import VimeoVideoFrame from "../custom-widgets/vimeo-video-frame";

// Components
import HeroChevron from "../hero/hero-chevron";
import BreadcrumbAuto from "../breadcrumb/breadcrumb-auto";
import ContactInfo from "../contact-info/contact-info";
import SEO from "../seo/seo";
import getHeroImgVariables from "../../helpers/getHeroImgVariables";
import DebitCardsOpenAccount from "../sign-up/debit-cards-open-account";
import BestBanksDefault from "../best-banks/best-banks-default";
import FeaturesSection from "../rewards-checking/features-section";

export const query = graphql`
  query stateFreeCheckingHeroQuery(
    $slug: String
    $pathXXL: String
    $pathXL: String
    $pathLG: String
    $pathMD: String
    $pathSM: String
    $pathXS: String
  ) {
    imgVariableXXL: file(relativePath: { eq: $pathXXL }) {
      ...heroChevronImageFragmentXXL
    }
    imgVariableXL: file(relativePath: { eq: $pathXL }) {
      ...heroChevronImageFragmentXL
    }
    imgVariableLG: file(relativePath: { eq: $pathLG }) {
      ...heroChevronImageFragmentLG
    }
    imgVariableMD: file(relativePath: { eq: $pathMD }) {
      ...heroChevronImageFragmentMD
    }
    imgVariableSM: file(relativePath: { eq: $pathSM }) {
      ...heroChevronImageFragmentSM
    }
    imgVariableXS: file(relativePath: { eq: $pathXS }) {
      ...heroChevronImageFragmentXS
    }
    imgVariableXXS: file(relativePath: { eq: $pathXS }) {
      ...heroChevronImageFragmentXXS
    }
    strapiBranchState: strapiBranchStates(Slug: { eq: $slug }) {
      StateCode
      StateName
      Slug
    }
  }
`;

const stateBranchesTemplateLimited = ({ location, data }) => {
  const stateData = data.strapiBranchState;
  const StateName = stateData.StateName;
  const StateCode = stateData.StateCode;

  const pageLocation = { location };

  const heroAltText = {
    AZ: "Grand Canyon, Arizona",
    CA: "Lupine flowers in Alviso Marsh, San Jose, California",
    ID: "Shoshone Falls, Idaho",
    OR: "Oregon",
    WA: "Mt. Rainier, Washington",
    NM: "New Mexico",
    UT: "Utah",
    NV: "Nevada mountains",
    TX: "Texas Bluebonnet flowers"
  };

  const heroChevronData = {
    id: "state-branches-hero",
    ...getHeroImgVariables(data),
    altText: heroAltText[StateCode],
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: `Open a *Free Checking Account in ${StateName}`
          }
        },
        {
          id: 2,
          button: {
            id: "free-checking-hero-cta-1",
            text: "Open an Account",
            url: "/open-an-account",
            class: "btn-white",
            containerClass: "mb-3"
          }
        },
        {
          id: 3,
          tertiaryText: {
            class: "text-white",
            text: "*Nonsufficient funds charge may apply."
          }
        }
      ]
    }
  };

  const freeCheckingVideoData = {
    vimeoId: "733695543",
    allowFeatures: "fullscreen",
    title: "WaFd Bank Free Checking video",
    class: "m-auto iframe w-100 border-radius-12"
  };

  //Meta Description
  const metaDescription = {
    AZ: "Discover free checking accounts at WaFd Bank in Arizona. From Phoenix to Tucson, WaFd Bank is here for you. Open an account online or at one of our az branches.",
    CA: "Open a free checking account at WaFd Bank in California. We have multiple branches in Northern and Southern California including San Jose & Long Beach.",
    NM: "Discover free checking at WaFd Bank in New Mexico. From Albuquerque to Roswell, WaFd Bank is here for you. Open an account online or at one of our NM branches.",
    NV: "Discover free checking at WaFd Bank in Nevada. From Las Vegas to Elko, WaFd Bank is here for you. Open an account online or at one of our NV branches.",
    OR: "Discover free checking at WaFd Bank in Oregon. From Portland to Medford, WaFd Bank is here for you. Open an account online or at one of our OR branches.",
    ID: "Discover free checking at WaFd Bank in Idaho. From Boise to Idaho Falls, WaFd Bank is here for you. Open an account online or at one of our ID branches.",
    TX: "Discover free checking at WaFd Bank in Texas. From Dallas to Denton and Plano, WaFd Bank is here for you. Open an account online or at one of our TX branches.",
    UT: "Discover free checking at WaFd Bank in Utah. From Salt Lake City to Price, WaFd Bank is here for you. Open an account online or at one of our UT branches.",
    WA: "Discover free checking at WaFd Bank in Washington. From Seattle to Walla Walla, WaFd Bank is here for you. Open an account online or at one of our WA branches."
  };

  const description = metaDescription[StateCode];
  const title = "Free Checking Account in " + StateName;

  const ogImagePath = {
    AZ: "og-free-checking-arizona-01042022.jpg",
    CA: "og-free-checking-california-020924.jpg",
    NM: "og-free-checking-new-mexico-01042022.jpg",
    NV: "og-free-checking-nevada-01042022.jpg",
    UT: "og-free-checking-utah-01042022.jpg",
    OR: "og-free-checking-oregon-01042022.jpg",
    ID: "og-free-checking-idaho-01042022.jpg",
    TX: "og-free-checking-texas-01042022.jpg",
    WA: "og-free-checking-washington-01042022.jpg"
  };

  const ogImage = "https://www.wafdbank.com/images/adsearch/states/" + ogImagePath[StateCode];

  const SEOData = {
    title: title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: "WaFd Bank"
      },
      {
        name: "description",
        content: description
      },
      {
        property: "og:title",
        content: title
      },
      {
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/personal-banking/free-checking-account/" + stateData.Slug
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: ogImage
      }
    ]
  };

  // Introduction content City Names
  const cityLocationsData = {
    AZ: [
      {
        name: "Mesa",
        url: "/locations/arizona/mesa"
      },
      {
        name: "Phoenix",
        url: "/locations/arizona/phoenix"
      },
      {
        name: "Tucson",
        url: "/locations/arizona/tucson"
      }
    ],
    CA: [
      {
        name: "Santa Rosa",
        url: "/locations/california/santa-rosa"
      },
      {
        name: "San Jose",
        url: "/locations/california/san-jose"
      },
      {
        name: "Long Beach",
        url: "/locations/california/long-beach"
      }
    ],
    ID: [
      {
        name: "Boise",
        url: "/locations/idaho/boise"
      },
      {
        name: "Nampa",
        url: "/locations/idaho/nampa"
      }
    ],
    NV: [
      {
        name: "Las Vegas",
        url: "/locations/nevada/las-vegas"
      },
      {
        name: "Fernley",
        url: "/locations/nevada/fernley"
      }
    ],
    NM: [
      {
        name: "Albuquerque",
        url: "/locations/new-mexico/albuquerque"
      },
      {
        name: "Roswell",
        url: "/locations/new-mexico/roswell"
      },
      {
        name: "Santa Fe",
        url: "/locations/new-mexico/santa-fe"
      }
    ],
    OR: [
      {
        name: "Bend",
        url: "/locations/oregon/bend"
      },
      {
        name: "Eugene",
        url: "/locations/oregon/eugene"
      },
      {
        name: "Grants Pass",
        url: "/locations/oregon/grants-pass"
      }
    ],
    TX: [
      {
        name: "Austin",
        url: "/locations/texas/austin"
      },
      {
        name: "Plano",
        url: "/locations/texas/plano"
      }
    ],
    UT: [
      {
        name: "Salt Lake City",
        url: "/locations/utah/salt-lake-city"
      },
      {
        name: "Draper",
        url: "/locations/utah/draper"
      }
    ],
    WA: [
      {
        name: "Seattle",
        url: "/locations/washington/seattle"
      },
      {
        name: "Kirkland",
        url: "/locations/washington/kirkland"
      },
      {
        name: "Everett",
        url: "/locations/washington/everett"
      }
    ]
  };
  function getCityNamesForAState() {
    return cityLocationsData[StateCode];
  }

  const debitCardData = {
    titleText: "Let's Open Your Free Checking Account Today",
    sectionId: "free-checking-debit-cards-section",
    depositText: "Only $25 initial deposit to open."
  };

  return (
    <SecondaryLanding footerBorder={StateName === "California" ? true : false}>
      <SEO {...SEOData} />
      <HeroChevron {...heroChevronData} />
      <BreadcrumbAuto {...pageLocation} />
      <section className={`container pb-0`}>
        <h1>Free Checking Account in {StateName}</h1>
        <div className="row">
          <div className="col-md-6 mb-4 mb-md-0">
            <VimeoVideoFrame {...freeCheckingVideoData} />
          </div>
          <div className="col-md-6">
            <h2 className="text-success">Everything you need and more, all for free</h2>

            <p>
              Are you tired of paying monthly fees for your checking account? WaFd Bank now features free checking
              accounts at all of our {StateName} locations. From{" "}
              {getCityNamesForAState().map((city, index) => (
                <span key={index}>
                  <Link to={city.url}>{city.name}</Link> {index !== getCityNamesForAState().length - 1 ? " to" : ""}{" "}
                </span>
              ))}
              and everywhere in-between.
            </p>
            <p>
              Our Free Checking Account offers a hassle-free banking experience with{" "}
              <span className="text-success font-weight-bold">no minimum balance requirements</span> and{" "}
              <span className="text-success font-weight-bold">no monthly maintenance fees</span>*. Whether you're
              opening your first account or{" "}
              <Link to="/blog/banking-101/how-to-switch-banks" id="how-to-switch-banks-blog-link">
                looking to switch
              </Link>{" "}
              to a more cost-effective option, our Free Checking Account is designed to meet your needs.
            </p>
            <div className="row align-items-center mb-3">
              <div className="col-sm-auto col-md-12 col-lg-auto mb-3 mb-sm-0 mb-md-3 mb-lg-0">
                <Link
                  to="/personal-banking/checking-account#checking-overview-section"
                  id="checking-compare-accounts-link"
                  className="btn btn-link w-100 w-sm-auto"
                >
                  Compare Checking Accounts
                </Link>
              </div>
              <div className="col-sm col-md col-lg">
                <Link to="/open-an-account" id="open-account-link" className="btn btn-primary w-100 w-sm-auto">
                  Open an Account
                </Link>
              </div>
            </div>
            <p id="free-checking-benefits-disclaimer" className="mb-0 text-muted text-sm">
              *Nonsufficient Funds Charge may apply.
            </p>
          </div>
        </div>
      </section>
      <FeaturesSection />
      <DebitCardsOpenAccount {...debitCardData} />
      <ContactInfo bgClass="bg-white" />
      {StateName !== "California" && <BestBanksDefault />}
    </SecondaryLanding>
  );
};

stateBranchesTemplateLimited.propTypes = {
  branch: PropTypes.object
};

export default stateBranchesTemplateLimited;
